@import '/src/theme';

.root {
    display: flex;
    align-items: flex-start;
    padding-bottom: 18px;
}

.avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
}

.name {
    color: $accent;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
}

.role {
    color: $secondary;
    font-size: 14px;
    line-height: 20px;
    max-width: 212px;
}

// .item_icon {
//     color: $accent;
// }
