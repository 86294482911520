.root {
    transition: all 0.1s ease;
    display: flex;
    color: var(--color-7);
    cursor: pointer;
    font: var(--head-6);
    padding: 10px;

    &:not(:last-child) {
        // margin-bottom: 10px;
    }

    &:hover {
        color: var(--color-8);
        background: var(--color-2);
    }
}

.selected {
    color: var(--color-8);
    background: var(--color-2);
}
