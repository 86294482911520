.inner {
    margin-top: -8px;
    display: flex;
    align-items: flex-start;
    column-gap: 15px;
}

.textarea {
    // min-height: 80px;
    flex-grow: 1;
}

.input {
    width: 140px;
}
