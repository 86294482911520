@import '/src/theme';

.root {
    flex-grow: 1;
}

.wrapper {
    position: relative;
    min-height: 180px;
}

.filters {
    position: relative;
}

.filters_loading {
    pointer-events: none;
    opacity: 0.9;
}
