@mixin font-face($font-family, $url, $weight: 400, $style: normal) {
    @font-face {
        font-family: '#{$font-family}';
        src: url('#{$url}.eot');
        src: url('#{$url}.eot?#iefix') format('embedded-opentype'), url('#{$url}.woff2') format('woff2'),
            url('#{$url}.woff') format('woff'), url('#{$url}.ttf') format('truetype');
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}

@include font-face('Inter', '/theme/fonts/inter/inter-regular', 400, normal);
@include font-face('Inter', '/theme/fonts/inter/inter-medium', 500, normal);
@include font-face('Inter', '/theme/fonts/inter/inter-semibold', 600, normal);
@include font-face('Inter', '/theme/fonts/inter/inter-bold', 700, normal);
