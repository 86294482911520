@import '/src/theme';

.root {
    font: var(--head-5);
    text-align: center;
    margin-bottom: 20px;

    @include media {
        margin-bottom: 25px;
    }
}
