.root {
    position: relative;
    // padding-top: 64px;
    // display: flex;
    min-height: 100vh;
    flex-grow: 1;

    [data-app-preloader] {
        z-index: 2000;
    }
}

.content {
    flex-grow: 1;
    // margin: 0 auto;
}

:global {
    :root {
        --toastify-z-index: 9999999;
    }

    #ReactSimpleImageViewer {
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 999;

        .react-simple-image-viewer__close {
            opacity: 0.5;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
        display: none;
    }

    .react-horizontal-scrolling-menu--scroll-container {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .react-responsive-modal-root {
        // z-index: 999999;
    }
}
