// Deprecated файл, использовался для стилизации аналитика/старого шаблона
@import '/src/theme/core/var.css';
@import 'react-responsive-modal/styles';
@import 'react-toastify/dist/ReactToastify';

@import '/src/app/ui/loader/loader';
// @import '/src/theme/@deprecated/components/ui/icon';
@import '/src/theme/@deprecated/components/ui/scroll';
@import '/src/theme/core/fonts.scss';

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--font-main);
    outline: none;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;
}

html {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
}

img {
    max-height: 100%;
    max-width: 100%;
}

picture {
    display: inline-block;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
}
