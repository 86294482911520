@import '@/pages/analytics/components/ui/select/index.style.scss';

.item {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    line-height: 18px;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
}
