.root {
    max-height: 582px;

    [data-elisa-scroll] [class^='simplebar-track simplebar-vertical'] {
        right: 2px;
        width: 6px;
    }

    [data-elisa-scroll] [class^='simplebar-track simplebar-horizontal'] [class^='simplebar-scrollbar'] {
        top: 2px;
        height: 7px;
        cursor: pointer;
        pointer-events: all;

        &::before {
            transition: 0.1s all ease;
        }
    }
}
