$background: #f0f4f7;
$border: #dce0eb;
$border-dark: #b5bdc3;
$white: #fff;

$primary: #2f3441;
$secondary: #6b7683;
$tertiary: #bfc5ce;

$accent: #1e4bd2;
$accent-dark: #002882;
$accent-light: #d7e0f9;
$blue: #00aaff;
$blue-light: #e0f4ff;
$dark: #2f3441;

$text: #2f3441;

$shadowSelect: 0px 4px 4px rgba(47, 52, 65, 0.04), 0px 8px 24px rgba(47, 52, 65, 0.12);

$font-main: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;

:export {
    background: $background;
    border: $border;
    borderDark: $border-dark;
    white: $white;

    primary: $primary;
    secondary: $secondary;
    tertiary: $tertiary;

    accent: $accent;
    accentDark: $accent-dark;
    accentLight: $accent-light;
    blue: $blue;
    blueLight: $blue-light;
    dark: $dark;

    shadowSelect: $shadowSelect;
}

/* -------------------------------------------------------------------------- */
/*                                 Deprecated                                 */
/* -------------------------------------------------------------------------- */
$ui-prefix: 'ui' !default;

// Breakpoints
$lg: 1200px;
$md: 992px;
$sm: 768px;
// $xs: 479px;

$breakpoints: (
    'lg': $lg,
    'md': $md,
    'sm': $sm,
    // 'xs': $xs,
);

// Containers
$container-width: 1200px;
$container-width-lg: 992px;
$container-width-md: 768px;
$container-width-sm: 100%;
// $container-width-xs: 100%;

$containers: (
    'lg': $container-width-lg,
    'md': $container-width-md,
    'sm': $container-width-sm,
    // 'xs': $container-width-xs,
);

$columns: 12 !default;
$offset: 40px;
$offset-lg: 20px;
$offset-md: 20px;
$offset-sm: 20px;
// $offset-xs: 15px;

$offsets: (
    'lg': $offset-lg,
    'md': $offset-md,
    'sm': $offset-sm,
    // 'xs': $offset-xs,
);
