@import '@/pages/@deprecated/references/styles/mixins';

.container {
    background-color: #f7fafc;

    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.list {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    padding: 10px 0;
}

.item {
    @include layout-width(3, 24);
    display: flex;
    gap: 10px;
    padding: 10px;

    &:not(:nth-last-child(-n + 3)) {
        border-bottom: 1px solid #dce0eb;
    }

    div:first-child {
        @include layout-width(2, 10);
        color: #757575;
    }

    div:last-child {
        @include layout-width(2, 10);
        color: #000000;
    }
}

.button {
    @include button-reset;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;

    padding: 10px 10px 20px;

    color: #1c4bcf;

    display: flex;
    align-items: center;
    gap: 8px;
}
