.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.title {
    font-size: 40px;
    line-height: 46px;
    font-weight: 400;
    color: #000000;
}

.whiteButton {
    cursor: pointer;
    padding: 15px 20px;
    border: 1px solid #1c4bcf;
    border-radius: 3px;
    background-color: #ffffff;
    color: #1c4bcf;
}

.more {
    @extend .whiteButton;

    align-self: flex-start;
}
