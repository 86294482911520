.root {
}

.grid {
    display: flex;
    align-items: center;
}

.divider {
    border-top: 1px solid #e0e0e0;
    margin: 12px -12px 12px;
}

.item {
    &:not(:last-child) {
        margin-right: 16px;
    }
}
