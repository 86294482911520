@import '/src/theme/';

/* -------------------------------------------------------------------------- */
/*                                    Menu                                    */
/* -------------------------------------------------------------------------- */

.menu {
    position: fixed;
    bottom: 16px;
    right: 16px;
    padding: 14px 20px;
    border-radius: 20px;
    background: $white;
    box-shadow: 0px 0px 50px 0px rgba(46, 78, 106, 0.12);
    border-bottom-right-radius: 0;
}

.menu_title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 10px;
}

.menu_action {
    display: flex;
    align-items: center;
    column-gap: 10px;
    color: $accent;
    cursor: pointer;
}

.menu_badge {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background: $accent;
    padding: 0 14px;
    height: 20px;
    border-radius: 200px;

    color: white;
}

.menu_arrow {
    margin-left: 8px;
    color: $accent;
}

/* -------------------------------------------------------------------------- */
/*                                   Drawer                                   */
/* -------------------------------------------------------------------------- */

.drawer {
    position: relative;

    :global(.mantine-Drawer-body) {
        padding: 0;
        height: 100%;
    }
}

.drawer_inner {
    height: 100%;
}

.drawer_close {
    display: flex;
    align-items: center;
    column-gap: 16px;
    color: $accent;
    padding: 20px 32px;
    border-bottom: 1px solid #dce0eb;
    cursor: pointer;

    &:hover {
        color: shade($accent, 0.2);
    }

    span {
        font-size: 16px;
        font-weight: 400;
    }
}

.drawer_complectation {
    padding: 18px 32px 0;
    display: flex;
    flex-direction: column;
    column-gap: 8px;

    &_title {
        font-size: 18px;
        font-weight: 600;
    }

    &_label {
        font-size: 16px;
        font-weight: 400;
        padding-bottom: 24px;
        border-bottom: 1px solid $border;
    }
}

.drawer_rrc {
    border-bottom: 1px solid $border;

    &_base {
        font-size: 16px;
        font-weight: 400;
        color: $secondary;
        display: flex;
        align-items: center;
        column-gap: 16px;
        padding: 21px 32px 18px;
    }

    &_discount {
        font-size: 16px;
        font-weight: 400;
        color: $secondary;
        display: flex;
        align-items: center;
        column-gap: 16px;
        padding: 0 32px 18px;
        margin-top: -12px;
    }

    &_label {
        font-size: 16px;
        font-weight: 400;
        padding: 0 32px 5px;
    }

    &_value {
        font-size: 20px;
        font-weight: 600;
        padding: 0 32px 30px;
    }
}

.drawer_options {
    height: 100%;
    max-height: calc(100% - 540px);

    &_title {
        font-size: 16px;
        font-weight: 400;
        color: $accent;
        padding: 30px 32px 16px;
    }

    &_count {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: $white;
        padding: 0 14px;
        background: $accent;
        position: relative;
        left: 8px;
        top: -6px;
    }

    &_list {
        display: flex;
        flex-direction: column;
        padding: 0 32px;
        width: 100%;

        &_item {
            display: flex;
            align-items: center;
            column-gap: 20px;
            padding: 12px 0;
            border-bottom: 1px solid $border;

            &_label {
                flex-grow: 1;
                font-size: 16px;
                font-weight: 400;
            }

            &_value {
                font-size: 18px;
                font-weight: 600;
                white-space: nowrap;
            }

            &_action {
                margin-left: auto;
                color: $accent;
                cursor: pointer;
                flex-shrink: 0;

                &:hover {
                    color: shade($accent, 0.4);
                }
            }
        }
    }
}

.drawer_action {
    margin: 30px 32px 0;
}

.drawer_action_new {
    margin: 20px 32px 0;
    pointer-events: all !important;
}

.drawer_action_carstock {
    margin: 20px 32px 0;
}
