.paper {
    box-shadow: none;
    z-index: 1;
    background: transparent;
}

.root {
    // background: var(--a-color-indigo);
    // background: transparent;
    // color: black;
    padding: 12px 24px;
    // margin: 0 auto;
    // max-width: 1200px;
    color: var(--a-color-black);
    width: 100%;
}

.button {
    padding: 8px;
    margin-right: 16px;
}

.icon {
    font-size: 42px;
}

.title {
    font-weight: 500;
    font-size: 20px;
}

.accountButton {
    padding: 8px;
    margin-left: auto;
}

.accountIcon {
    font-size: 48px;
}

.menuItem {
    font-size: 16px;
}

.menuIcon {
    color: var(--a-color-dark-gray);
    margin-right: 8px;
    font-size: 24px;
}
