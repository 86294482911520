@import '/src/theme';

$ui-container: '#{$ui-prefix}-container' !default;
$container-space: 4 !default;
$container-space-step: 5 !default;
$container-space-start: 10 !default;

.#{$ui-container} {
    max-width: $container-width;
    min-width: $container-width;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding-left: $offset;
    padding-right: $offset;
    transition: all 0.3s ease-in-out;

    // &[data-space] {
    //     padding-left: #{$container-space-start}px;
    //     padding-right: #{$container-space-start}px;
    // }

    // @for $i from 1 through $container-space {
    //     &[data-space='#{$i}'] {
    //         padding-left: #{$i * $container-space-step + $container-space-start}px;
    //         padding-right: #{$i * $container-space-step + $container-space-start}px;
    //     }
    // }
}

@each $point, $value in $breakpoints {
    @include media($point, 'max') {
        @each $point_containers, $value in $containers {
            @if $point == $point_containers {
                .#{$ui-container} {
                    max-width: $value;
                }
            }
        }

        // @each $point_offsets, $value in $offsets {
        //     @if $point == $point_offsets {
        //         .#{$ui-container} {
        //             padding-left: $value;
        //             padding-right: $value;

        //             @for $i from 1 through $container-space {
        //                 &[data-space-#{$point_offsets}='#{$i}'] {
        //                     padding-left: #{$i * $container-space-step + $container-space-start}px;
        //                     padding-right: #{$i * $container-space-step + $container-space-start}px;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}
