@import '/src/theme';

.root {
    width: calc(100% + 96px);
    margin: 0 -48px;
    position: relative;

    > div > div {
        width: 100%;
    }

    :global(.mantine-ScrollArea-scrollbar) {
        z-index: 2;
    }
}

.table {
    border-collapse: separate;
    border-spacing: 0;

    &.table_left_sticky {
        .tr {
            td,
            th {
                &:nth-child(1) {
                    border-right: 1px solid #dce0eb;
                }
            }
        }
    }

    &.table_left_sticky_selected {
        .tr {
            td,
            th {
                &:nth-child(2) {
                    border-right: 1px solid #dce0eb;
                }
            }
        }
    }

    &.table_right_sticky {
        .td_right_sticky {
            border-left: 1px solid #dce0eb !important;
        }
    }
}

.table_left_sticky {
    .tr {
        th {
            &:nth-child(1) {
                position: sticky;
                top: 0;
                left: -24px;
                z-index: 1;
            }
        }

        td {
            &:nth-child(1) {
                position: sticky;
                left: -24px;
                z-index: 1;
            }
        }
    }
}

.table_left_sticky_selected {
    .tr {
        th {
            &:nth-child(1) {
                position: sticky;
                top: 0;
                left: 0;
                z-index: 1;
            }

            &:nth-child(2) {
                position: sticky;
                top: 0;
                left: 93px;
                z-index: 1;
            }
        }

        td {
            &:nth-child(1) {
                position: sticky;
                left: 0;
                z-index: 1;
            }

            &:nth-child(2) {
                position: sticky;
                left: 93px;
                z-index: 1;
            }
        }
    }
}

.thead {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;

    .tr {
        border-top: 1px solid #dce0eb;

        th {
            background: #fff;
            border-top: 1px solid #dce0eb;
        }
    }
}

.tbody {
    .tr {
        border-top: 1px solid #dce0eb;

        &:last-child {
            td {
                padding-bottom: 40px !important;
            }
        }

        td {
            background: #fff;
        }
    }
}

.th {
    padding: 16px 25px !important;

    &:first-child {
        min-width: 60px;
        padding-left: 48px !important;
    }

    &:last-child {
        min-width: 60px;
        padding-right: 48px !important;
    }

    span {
        color: #2f3441;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
    }
}

.td {
    padding: 6px 25px !important;

    &:first-child {
        min-width: 60px;
        padding-left: 48px !important;
    }

    &:last-child {
        min-width: 60px;
        padding-right: 48px !important;
    }

    :global(.elisa-table-widget-cell) {
        white-space: nowrap;
        max-width: 282px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    span {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #323232;
    }

    button {
        background-color: transparent;
        border: none;
        cursor: pointer;
    }
}

.td_selected {
    // padding-right: 0px !important;
}

// .td_text {
//     min-width: 160px;
// }

.td_right_sticky {
    position: sticky;
    right: -24px;
    // border-left: 1px solid #dce0eb !important;
}

.img {
    padding: 6px;

    img {
        width: 100%;
        height: 78px;
        object-fit: contain;
    }
}

.searching {
    pointer-events: none;
    opacity: 0.6;
}
