@import '/src/theme/';

.root {
    position: relative;

    :global(.mantine-Input-input) {
        border: none;

        &[disabled] {
            // background: #dcdddf;
            background: #e2e3e5;
        }
    }

    :global(.mantine-Input-input)::placeholder,
    :global(.mantine-MultiSelect-searchInput)::placeholder {
        color: #2f3441;
    }

    :global(.mantine-CloseButton-root) {
        border-radius: 12px;
        width: 24px;
        height: 24px;

        svg {
            width: 14px;
            height: 14px;
        }

        &:hover {
            background: rgba(248, 249, 250, 1);
        }
    }
}

.grid {
    flex-grow: 1;

    :global(.elisa-table-filter) {
        max-width: 240px;
        width: 100%;
    }
}

.grid_reset {
    padding-right: 260px;
}

.create {
    margin-right: auto;
}
