.root {
    padding: 10px;
    * {
        font-family: 'Inter', sans-serif !important;
    }
}

.search {
    margin-bottom: 12px;
}

.row {
    padding: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font: var(--head-5);
    border-radius: 8px;
    color: var(--a-color-black);
}

.rowIcon {
    margin-right: 12px;
    color: var(--a-color-dark-gray);
}

.isActive {
    color: var(--a-color-blue);

    .rowIcon {
        color: var(--a-color-blue);
    }
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-height: 200px;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 1;
}
