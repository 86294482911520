.root {
    width: 60px;
}

.dropdown {
    > div {
        padding: 16px;
    }

    :global(.mantine-Menu-label) {
        padding-bottom: 8px;
        font-size: 14px;
    }

    :global(.mantine-Menu-item) {
        font-size: 14px;

        &:first-child {
            padding-top: 8px;
        }

        &:not(:last-child) {
            padding-bottom: 8px;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }
}
