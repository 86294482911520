@import '/src/theme/';

.root {
    position: absolute;
    right: 4px;
    top: 4px;
    border-radius: 50%;
    padding: 4px;
    min-width: 20px;
    height: 20px;
    color: var(--color-1);
    background-color: var(--color-5);
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item {
    margin: 2px 0;

    &:hover {
        text-decoration: underline;
    }
}

.dropdown {
    font-size: 14px;
    font-weight: 400;
    color: $dark;
}

.title {
    margin-bottom: 4px;
}

.list_item {
    max-width: 220px;
    overflow: hidden;
    margin-bottom: 2px;

    &:hover {
        text-decoration: underline;
    }
}

.list_title {
}

.count {
    font-size: 12px;
    font-weight: 500;
    padding: 1px 8px;
    border-radius: 12px;
    background: $accent;
    color: $white;
    position: relative;
    top: -6px;
    right: -4px;
}
