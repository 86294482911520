@import '/src/theme';

.layout {
    display: flex;
}

.layout_content {
    background: $background;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 56px 0 200px;
}

.layout_wrapper {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
}
