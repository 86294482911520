@import '/src/theme';

.root {
    border-radius: 32px;
    padding: 32px 48px;
    transition: all 0.1s ease;

    &.main {
        padding: 48px;

        .title {
            cursor: auto;
            font-size: 32px;
            line-height: 44px;
        }
    }
}

.title {
    font-weight: 600;
    color: $primary;
    font-size: 24px;
    line-height: 32px;
    cursor: pointer;
    position: relative;
    padding-right: 80px;
}

.icon {
    position: absolute;
    top: -3px;
    right: 0;

    svg {
        color: $secondary;
    }
}

.children {
    position: relative;
    // overflow-x: auto;
    // overflow-y: hidden;
}
