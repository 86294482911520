@import '@/pages/@deprecated/references/styles/mixins';

.root {
    position: relative;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.search {
    position: relative;
    padding: 10px 16px;

    input {
        @include input-reset;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: rgba(0, 0, 0, 0.54);
        border: 1px solid rgba(0, 0, 0, 0.54);
        border-radius: 4px;
        width: 100%;
        padding: 8px 40px 8px 16px;
    }

    svg {
        position: absolute;
        right: 28px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }
}

.hint {
    padding: 10px 16px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.54);
}

.label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #737373;
}

.button {
    @include button-reset;
    padding: 8px 12px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);
    background: white;

    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
        flex: 0 1 auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    svg {
        flex: 0 0 24px;
        transition: all 150ms ease-in-out;
    }
}

.open {
    composes: button;

    svg {
        transform: rotate(-180deg);
    }
}

.list {
    position: absolute;
    background-color: #fff;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2), 0 9px 10px rgba(0, 0, 0, 0.14), 0 5px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: 300px;
    right: 0;
    top: calc(100% + 4px);
    z-index: 1;
    outline: none;

    :global(.simplebar-vertical) {
        width: 7px;
    }

    :global(.simplebar-scrollbar.simplebar-visible):before {
        opacity: 1;
        background-color: #acb6c3;
    }
}

.item {
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.54);
    cursor: pointer;
}

.highlighted {
    composes: item;
    background: rgba(25, 118, 210, 0.08);
}
