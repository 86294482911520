.root {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    min-height: 32px;

    :global(.mantine-Checkbox-inner) {
        top: 2px;
    }

    :global(.mantine-Checkbox-label) {
        font-size: 16px;
        line-height: 24px;
        padding-left: 20px;
    }
}

.searching {
    pointer-events: none;
    opacity: 0.7;
}

.buttons {
    margin-left: 40px;
    display: flex;
    align-items: center;
    row-gap: 24px;
    column-gap: 24px;
}

.modal {
    :global(.mantine-Modal-title) {
        font-size: 18px;
        line-height: 24px;
    }

    :global(.mantine-Text-root) {
        margin-bottom: 24px;
    }
}
