@import '@/pages/@deprecated/references/styles/mixins';

.root {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.label {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #737373;
}

.input {
    @include input-reset;
    padding: 8px 12px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.87);

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
