.container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    padding-bottom: 24px;
    border-bottom: 2px solid #dce0eb;
}

.panel {
    display: flex;
    align-items: center;
    gap: 111px;
    background-color: #f7fafc;
    padding: 25px 78px;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.prompt {
    color: #8790a0;
}

.button {
    padding: 15px 47px;
    border: none;
    background-color: #1c4bcf;
    border-radius: 5px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #ffffff;
    align-self: flex-start;
    cursor: pointer;
}

.file {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fileStatus {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #8790a0;
}

.fileButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 100px;

    button {
        background-color: #ffffff;
        border: 1px solid #1c4bcf;
        color: #1c4bcf;
        border-radius: 3px;
        padding: 15px 20px;
        cursor: pointer;
    }
}

.fileName {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #8790a0;
}

.hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    background: transparent;
    overflow: hidden;
}

.editing {
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.input {
    padding: 14px 20px;
    border: 1px solid #acb6c3;
    border-radius: 3px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    outline: none;
    color: #6b7683;
    min-width: 500px;
}
