.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    padding-top: 20px;
}

.group {
    border-bottom: 1px solid #e9e8e8;
    padding-bottom: 12px;
}

.grid {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
}

.columns {
    display: flex;
    align-items: flex-start;
    column-gap: 75px;

    > div {
        max-width: 50%;
    }
}
