@import '/src/theme';

.title {
    color: $primary;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
}

.searching {
    * {
        opacity: 0.5;
    }
}
