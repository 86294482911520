@import '/src/theme';

.root {
    &:hover {
    }
}

.title {
    margin-bottom: 0;
    transition: margin-bottom 0.1s ease;

    &.active {
        margin-bottom: 16px;
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 28px 7px 20px;
    border: 0;
    color: #fff;
    background: $accent;
    font-weight: 600;
    font-size: 18px;
    line-height: 44px;
    border-radius: 15px;
    cursor: pointer;
    transition: background 0.1s ease;

    &:hover {
        background: mix(black, $accent, 10%);
    }
}

.show {
    width: 32px;
    height: 32px;
    padding: 0;
    border: 0;
    background: transparent;
    position: relative;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 21px;
        height: 3px;
        background: $white;
        border-radius: 3px;
    }

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 3px;
        height: 21px;
        background: $white;
        border-radius: 3px;
    }
}

.show .active {
    &:after {
        display: none;
    }
}

.grid {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
