.root {
}

.wrapper {
    padding: 100px 0;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: calc(50% - 100px);
    left: 50%;
    transform: translate(-50%, -50%);
}

.title {
    text-transform: uppercase;
    font: var(--head-5);
    letter-spacing: 1px;
    text-align: center;
    color: var(--color-error-text);
}

.button {
    margin-top: 20px;
    font: var(--head-6);
}
