.root {
    text-transform: initial;
    font: var(--head-5);

    &[class*='Mui-disabled'] {
        svg {
            color: rgba(0, 0, 0, 0.26);
        }
    }
}
