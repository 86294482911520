.root {
    display: block;
}

.title {
}

.sort {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 62px;
}

.grid {
    position: relative;
    min-height: 100px;
}
