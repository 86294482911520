.ui-button {
    height: 40px;
    padding: 0 20px;
    font: var(--head-5);
    color: var(--color-7);
    border-radius: 3px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    background: #efefef;
    letter-spacing: 1px;

    &:hover {
        opacity: 0.8;
    }
}

/**
 * Types
 */

.type {
    &-accent {
        background: var(--color-10);
        color: var(--color-1);
    }

    &-neutral {
        border-color: var(--color-10);
        background-color: transparent;
        color: var(--color-10);
    }

    &-neutral-error {
        border-color: var(--color-error-text);
        background-color: transparent;
        color: var(--color-error-text);
    }

    &-ghost {
        border-color: transparent;
        background-color: transparent;
        color: var(--color-10);
    }

    &-white {
        border-color: var(--color-1);
        background-color: var(--color-1);
        color: var(--color-7);
        border-radius: 0;

        &:hover {
            opacity: 0.98;
        }
    }
}

/**
 * Sizes
 */

.size {
    &-sm {
        padding: 0 16px;
        height: 32px;
    }

    &-lg {
        height: 48px;
    }
}

/**
 * Modify
 */

.disabled {
    background-color: var(--color-4);
    border-color: var(--color-4);
    cursor: no-drop;

    &:hover {
        background-color: var(--color-4);
        border-color: var(--color-4);
    }
}

.fluid {
    width: 100%;
}

.loading {
    cursor: progress;
}

.icon {
    margin-right: 10px;
}
