.root {
    padding: 10px;
    width: 356px;
    * {
        font-family: 'Inter', sans-serif !important;
    }

    [data-elisa-scroll] [class^='simplebar-track simplebar-vertical'] {
        right: 0px;
        width: 4px;
    }
}

.wrapper {
    position: relative;
}

.close {
    position: absolute;
    top: 5px;
    right: 0;
    color: var(--a-color-dark-gray);
    cursor: pointer;
    transition: 0.2s all ease;

    &:hover {
        opacity: 0.5;
    }
}

.title {
    padding: 8px 40px 8px 0;
    font: var(--head-5-7);
    margin-bottom: 12px;
}

.input {
    padding-right: 0;
    margin-bottom: 12px;
}
