.root {
    position: relative;
    box-shadow: 0px 0px 0px 1px #e0e0e0;
    border-radius: 4px;
    background: var(--a-color-white);
    padding: 12px;
    overflow: hidden;
}

.nopadding {
    padding: 0;
}
