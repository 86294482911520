.root {
    display: inline-flex;
    align-items: center;
    overflow: hidden;
}

.page,
.next,
.previous {
    transition: all 0.1s ease;
    display: flex;
    min-width: 48px;
    height: 46px;
    cursor: pointer;
    color: var(--color-7);
    font: var(--head-5);
    border: 1px solid var(--color-5);

    &:not(:last-child) {
        border-right: none;
    }

    &:hover:not([class*='active']):not([class*='disabled']) {
        background: var(--color-2);
        color: var(--color-7);
    }
}

.pageLink,
.activeLink,
.previousLink,
.nextLink,
.activeLink {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active {
    display: flex;
    background: var(--color-10);
    color: var(--color-1);
    border-color: var(--color-10);
    z-index: 1;
    border-color: transparent !important;

    // + li {
    //     border-left-color: var(--color-10);
    // }
}

.previous {
    display: flex;
    border-radius: 3px 0 0 3px;

    &Icon {
        transform: rotate(90deg);
    }
}

.next {
    display: flex;
    border-radius: 0px 3px 3px 0px;

    &Icon {
        transform: rotate(-90deg);
    }
}

.disabled {
    cursor: not-allowed;
    // display: none;
}

.disabledLink {
    cursor: not-allowed;
    // display: none;
}
