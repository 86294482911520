@import '/src/theme';

.root {
    background-color: inherit;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.searching {
    opacity: 0.5;
    pointer-events: none;
}

.image {
    border-radius: 32px;
    overflow: hidden;
    margin-bottom: 28px;
    position: relative;

    @include aspect-ratio('parent', 1100, 660);

    > div {
        @include aspect-ratio('child');
    }

    img {
        width: 100%;
        // height: 100%;
        object-fit: cover;
    }
}

.list {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin-bottom: 12px;
    column-gap: 30px;
    row-gap: 0;
}

.item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    color: $secondary;

    &::first-letter {
        text-transform: uppercase;
    }
}

.circle {
    position: absolute;
    top: 50%;
    left: -12px;
    transform: translate(-100%, -50%);
    background: $accent;
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    transition: all 0.1s ease;
    margin-bottom: 12px;
    cursor: pointer;

    &:hover {
        color: $accent;
    }
}

.dealer {
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
    row-gap: 15px;
    width: 100%;
}

.dealer_label {
    color: $secondary;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 15px;
}

.dealer_value {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $accent;
}

.price {
}

.price_label {
    color: $secondary;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 15px;
}

.price_value {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $primary;
}

.favorite {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 44px;
    height: 44px;
    z-index: 1;
    border-radius: 50%;

    svg {
        color: $accent;
        pointer-events: none;
    }
}

.favorite_active {
    svg {
        fill: $accent;
    }
}

.favorite_loading {
    opacity: 0.6;
}
