@import '/src/theme';

.root {
}

.button {
    padding: 0 24px;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
}

.active {
    background: $accent;
    color: $white;
}
