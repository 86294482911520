.root {
    transition: all 0.1s ease;
    border: 1px solid var(--color-5);
    border-radius: 3px;
    padding: 0 20px;
    display: inline-flex;
    color: var(--color-7);
    resize: vertical;
    width: 100%;
    height: 48px;
    font: var(--head-5);
    text-overflow: ellipsis;

    &:focus:not([class*='error']) {
        border-color: var(--color-8);
        box-shadow: 0px 0px 0px 2px var(--color-14);
    }

    &:not([class*='error']):not(:focus):not(:placeholder-shown) {
        border-color: var(--color-5);
        box-shadow: 0px 0px 0px 2px var(--color-3);
    }
}

.size {
    &-sm {
        height: 36px;
    }
}

.group {
    position: relative;
    width: 100%;

    .root {
        padding-right: 40px;
    }
}

.error {
    box-shadow: 0px 0px 0px 2px #f9cdd0;
    border-color: var(--color-13);
}

.icon {
    color: var(--color-7);
    position: absolute;
    right: 0px;
    top: calc(50%);
    transform: translate(-50%, -50%);
}
