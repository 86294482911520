.root {
    color: var(--a-color-dark-gray);
    font: var(--head-5);
    white-space: nowrap;
    border-radius: 12px;
}

.icon {
    margin-right: 8px;
    color: var(--a-color-dark-gray);
    font-size: 20px;
}

.endIcon {
    margin-left: 8px;
    color: var(--a-color-dark-gray);
    font-size: 20px;
}

.isActive {
    color: var(--a-color-blue);
}
