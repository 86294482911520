.root {
}

.button {
    color: var(--a-color-dark-gray);
    border-radius: 12px;
    white-space: nowrap;
}

.icon {
    color: var(--a-color-blue);
    margin-right: 8px;
}

.edit {
    display: flex;
    align-items: center;
    min-width: 100%;
}

.actions {
    display: flex;
    align-items: center;
}

.action {
    &:not(:last-child) {
        margin-right: 4px;
    }
}
