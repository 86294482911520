@import '/src/theme';

.root {
    border-bottom: 1px solid $border;
}

.tabs_list {
    border-bottom: 0;
    column-gap: 60px;
    row-gap: 8px;
    position: relative;
    padding-top: 26px;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.tab {
    font-size: 16px;
    line-height: 24px;
    line-height: 1;
    padding: 0 0 20px;
    color: #788196;
    margin: 0;

    &:hover {
        border-color: transparent;
    }

    &[data-active] {
        color: $accent;
    }
}
