$duration: 1.3s;
$container-size: 100px;
$box-size: 10px;
$box-border-radius: 20%;
$box-color: #008dc9;
$box-shadow: 0 0 6px 0 hsla(0deg, 0%, 60%, 0.6);

.root {
    transition: all 0.2s ease;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
}

.active {
    opacity: 1;
    visibility: visible;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: $container-size;
    z-index: 1;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &Top {
        align-items: flex-start;
    }
}

.overlay {
    background: rgba(#ffffff, 0.8);
    width: 100%;
    height: 100%;
}

.top {
    height: 100%;
    max-height: 420px;
    margin: 0 auto;
}

.item {
    width: $box-size;
    height: $box-size;
    position: relative;
    display: block;
    transform-origin: -50% center;
    border-radius: $box-border-radius;

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $box-color;
        border-radius: $box-border-radius;
        box-shadow: $box-shadow;
    }

    &:nth-child(1) {
        animation: vir-preloader-slide $duration ease-in-out infinite alternate;

        &:after {
            animation: vir-preloader-color-change $duration ease-in-out infinite alternate;
        }
    }

    @for $i from 1 to 5 {
        &:nth-child(#{$i + 1}) {
            animation: vir-preloader-flip-#{$i} $duration ease-in-out infinite alternate;

            &:after {
                animation: vir-preloader-squidge-#{$i} $duration ease-in-out infinite alternate;
            }
        }
    }

    // &:nth-child(2):after {
    //     background-color: lighten($box-color, 5%);
    // }

    // &:nth-child(3):after {
    //     background-color: lighten($box-color, 10%);
    // }

    // &:nth-child(4):after {
    //     background-color: lighten($box-color, 15%);
    // }

    // &:nth-child(5):after {
    //     background-color: lighten($box-color, 20%);
    // }
}

@keyframes vir-preloader-slide {
    0% {
        background-color: $box-color;
        transform: translateX(0vw);
    }

    100% {
        // background-color: lighten($box-color, 20%);
        transform: translateX(calc(#{$container-size} - (#{$box-size} * 1.25)));
    }
}

@keyframes vir-preloader-color-change {
    0% {
        background-color: $box-color;
    }

    100% {
        // background-color: lighten($box-color, 20%);
    }
}

@for $i from 1 to 5 {
    @keyframes vir-preloader-flip-#{$i} {
        0%,
        #{$i * 15}% {
            transform: rotate(0);
        }

        #{$i * 15 + 20}%,
        100% {
            transform: rotate(-180deg);
        }
    }

    @keyframes vir-preloader-squidge-#{$i} {
        #{$i * 15 - 10}% {
            transform-origin: center bottom;
            transform: scalex(1) scaley(1);
        }

        #{$i * 15}% {
            transform-origin: center bottom;
            transform: scalex(1.3) scaley(0.7);
        }

        #{$i * 15 + 10}%,
        #{$i * 15 + 5}% {
            transform-origin: center bottom;
            transform: scalex(0.8) scaley(1.4);
        }

        #{$i * 15 + 40}%,
        100% {
            transform-origin: center top;
            transform: scalex(1) scaley(1);
        }

        #{$i * 15 + 25}% {
            transform-origin: center top;
            transform: scalex(1.3) scaley(0.7);
        }
    }
}
