@import '/src/theme';

.root {
    display: block;
    color: $primary;
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 40px;
}

.badge {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    background: $accent;
    padding: 0 7px;
    height: 20px;
    border-radius: 200px;
    background: $accent;
    position: relative;
    top: -10px;
    right: -5px;
    color: white;
    letter-spacing: normal;
    border: none;
}
