.root {
    padding: 24px;
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    flex-grow: 1;
    position: relative;
}

.fluid {
    max-width: 100%;
}
