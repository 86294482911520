@import '/src/theme';

.root {
    z-index: 2;
    background: $white;
}

.sticky {
    position: sticky;
    top: 0;
    z-index: 999;
}

.navigation {
    border-bottom: 1px solid $border;
    // display: flex;
    // justify-content: center;
    // align-items: center;
}

.inner {
    display: flex;
    align-items: flex-end;
    column-gap: 56px;
    row-gap: 20px;
    padding: 18px 0 0;
    min-height: 80px;
}

.menu {
}

.bottom {
}
