@import '/src/theme';

.inner {
    margin-bottom: 30px;
}

.title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding-bottom: 6px;
    border-bottom: 1px solid $border;

    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
}

.title_opened {
}

.icon {
    display: flex;
    flex-direction: column;

    svg {
        transition: transform 0.1s ease;
    }
}

.icon_opened {
    svg {
        transform: rotate(90deg);
    }
}

.grid {
    margin-top: 30px;
}
