.root {
    transition: all 0.1s ease;
    display: flex;
    color: var(--color-7);
    cursor: pointer;
    font: var(--head-6);
    padding: 2px 14px;

    &:not(:last-child) {
        margin-bottom: 12px;
    }

    &:hover {
        color: var(--color-8);
    }
}

.selected {
    color: var(--color-8);
}

.checkbox {
    &Label {
        font: var(--head-6);
    }
}
