.root {
}

.isActive {
    background: var(--a-color-hover) !important;
}

.cell {
    font: var(--head-6-7);
}
