@import '/src/theme';

.root {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.button {
    margin-left: auto;
}
