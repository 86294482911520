.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 6px;
}

.title {
    font: var(--head-5);
    color: var(--a-color-dark-gray);
}

.reset {
    color: var(--a-color-blue);
}

.rows {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    max-height: 216px;
    overflow: auto;
}

.row {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
}

.content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 8px;
    padding: 4px;
}

.column {
    font: var(--head-5);
}

.icon {
    color: var(--a-color-dark-gray);
}

.drag {
    margin-top: 1px;
    margin-right: 12px;
    color: var(--a-color-dark-gray);
}
