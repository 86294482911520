.container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &:not(:last-of-type) {
        border-bottom: 2px solid #dce0eb;
    }
}

.open {
    composes: container;

    &:not(:last-of-type) {
        border-bottom: none;
    }

    .wrapper {
        background-color: #f7fafc;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 50px;
    padding: 24px 20px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: #f7fafc;

        .info {
            color: #1c4bcf;
        }
    }
}

.link {
    display: block;

    svg {
        pointer-events: none;
    }

    svg path {
        transition: all 0.3s ease-in-out;
    }

    &:hover svg path {
        fill: #1c4bcf;
    }
}

.info {
    width: 100%;
    max-width: 455px;

    display: flex;
    align-items: center;
    gap: 10px;

    transition: all 0.3s ease-in-out;

    svg,
    span:not(:first-of-type) {
        flex: 0 0 auto;
        color: #8790a0;
    }
}

.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

%status {
    display: flex;
    align-items: center;
    gap: 10px;

    &:before {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
}

.done {
    @extend %status;
    color: #27ae60;

    &:before {
        background-color: #27ae60;
    }
}

.pending {
    @extend %status;
    color: #2d9cdb;

    &:before {
        background-color: #2d9cdb;
    }
}

.new {
    @extend %status;
    color: #2d9cdb;

    &:before {
        background-color: #2d9cdb;
    }
}

.error,
.front-error {
    @extend %status;
    color: #eb5757;

    &:before {
        background-color: #eb5757;
    }
}
