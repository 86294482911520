.inner {
    display: flex;
    flex-direction: column;
}

.price {
    // font-weight: 600;
    // font-size: 24px;
    // line-height: 31px;
    // display: flex;
    // align-items: center;
    // column-gap: 8px;
    // margin-top: 10px;
    // margin-left: auto;
}

.rrc {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    // margin-top: 10px;
    // margin-left: auto;

    > div:last-child {
        margin-left: auto;
    }
}

.date {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #6b7683;
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-top: 20px;
    margin-left: auto;
}
