@import '/src/theme';

.root {
    position: absolute;
    top: 48px;
    right: 52px;
    z-index: 1;
}

.button {
    padding: 0 16px;
    height: 44px;
}
