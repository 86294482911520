@mixin container {
    width: 100%;
    max-width: 1272px;
    margin: 0 auto;
}

@mixin layout-width($num, $gap) {
    width: calc((100% - #{($num - 1) * $gap}px) / #{$num});
}

@mixin input-reset {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none;
}

// UI:
@mixin button-reset {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    cursor: pointer;
    outline: none;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

@mixin button-layout {
    @include button-reset;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;
    padding: 14px 20px;
}

@mixin button-blue {
    @include button-layout;
    border: 1px solid #1c4bcf;
    background-color: #1c4bcf;
    color: #ffffff;
}

@mixin button-blue-border {
    @include button-layout;
    border: 1px solid #1c4bcf;
    background-color: #ffffff;
    color: #1c4bcf;
}

@mixin select-list {
    position: absolute;
    transition: all 150ms ease-in-out;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(36, 47, 83, 0.06), 0 1px 6px rgba(0, 0, 0, 0.1), 0 5px 24px rgba(36, 47, 83, 0.16);
    border-radius: 3px;
    z-index: 1;
    overflow: hidden;
    outline: none;

    :global(.simplebar-vertical) {
        width: 7px;
    }

    :global(.simplebar-scrollbar.simplebar-visible):before {
        opacity: 1;
        background-color: #acb6c3;
    }
}

@mixin select-item {
    padding: 10px;
    font-size: 16px;
    line-height: 20px;
    color: #2f3441;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin select-item-highlighted {
    composes: item;
    background-color: #f3f7fa;
    color: #00aafc;
}
