.root {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(292px, 1fr));
    row-gap: 24px;
    column-gap: 24px;
    padding-bottom: 120px;
}

.field {
    flex-grow: 1;
    // max-width: 292px;
    width: 100%;
}
