@import '/src/theme';

.root {
    margin-bottom: 16px;
}

.grid {
}

.tab {
    min-height: 48px;
    padding: 6px 24px;
    border: 1px solid $border;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $text;
    cursor: pointer;
    border-radius: 12px;
    background: $white;
    font-family: $font-main;

    &:not(.tab_active):hover {
        border-color: shade($border, 0.1);
    }
}

.tab_active {
    border-color: $accent;
    color: $accent;
    font-weight: 400;
}
