@import '/src/theme/';

.table {
    border-collapse: separate;
    border-spacing: 0;
}

.thead {
    position: sticky;
    top: 0;
    background: $white;

    th {
        white-space: nowrap;
    }
}

.tfoot {
    position: sticky;
    bottom: -1px;
    background: $white;

    th {
        border-top: 0;
    }
}
