.root {
    display: flex;
    align-items: center;
    line-height: 1;
    position: relative;
}

.input {
    display: block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    cursor: pointer;

    // &:checked + .wrapper .icon {
    //     background: var(--color-10);
    //     border-color: var(--color-10);
    // }

    &:checked + .wrapper .icon::after {
        opacity: 1;
        margin-top: 0px;
    }
    &:checked + .wrapper .icon:not([data-indeterminate]) {
        border-color: var(--color-8);
        box-shadow: 0px 0px 0px 3px var(--color-14);
    }
}

.icon {
    display: inline-block;
    border: 1px solid var(--color-5);
    width: 24px;
    height: 24px;
    border-radius: 3px;
    vertical-align: middle;
    position: relative;
    flex-shrink: 0;
    background: var(--color-1);
    margin-top: 0px;
    transition: all 0.2s ease;

    &::after {
        content: '';
        position: absolute;
        display: block;
        right: 7px;
        top: 2px;
        width: 5px;
        height: 12px;
        border: solid var(--color-10);
        background: transparent;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
        margin-top: -2px;
        transition: all 0.1s ease-in-out;
    }

    &-indeterminate {
        transition: none;

        &::after {
            border: 0;
            width: 16px;
            height: 2px;
            background: var(--color-5);
            transform: rotate(0);
            right: 3px;
            top: 9px;
        }
    }
}

.wrapper {
    display: flex;
    align-items: flex-start;
    user-select: none;

    > *:not(:last-child) {
        margin-right: 15px;
    }
}

.label {
    display: inline-block;
    font: var(--head-5);
    margin-top: 0px;
}
