.image {
    position: relative;
    aspect-ratio: 97/50;
    width: 100px;
    cursor: pointer;
}

.image_inner {
    width: 100%;
    object-fit: cover;
    max-height: 51px;
}
