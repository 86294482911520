@import '/src/theme';

.root {
    flex-grow: 1;
}

.title {
    font-weight: 600;
    color: $primary;
    font-size: 32px;
    line-height: 44px;
    position: relative;
    margin-bottom: 44px;
}

.wrapper {
    position: relative;
    min-height: 180px;
}

.content {
    display: flex;
    flex-direction: column;
    row-gap: 28px;
    position: relative;
}

.content_loading {
    opacity: 0.9;
    pointer-events: none;
}
