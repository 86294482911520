@import '/src/theme';

.root {
    flex-grow: 1;
}

.wrapper {
    position: relative;
    min-height: 180px;
}

.content {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    position: relative;
}

.content_loading {
    opacity: 0.9;
    pointer-events: none;
}
