.root {
    max-width: 500px;
}

.title {
    text-align: center;
    margin-bottom: 12px;
    font: var(--head-3);
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    margin-bottom: 20px;
}

.actions {
    display: flex;
    align-items: center;

    button {
        &:first-child {
            margin-right: 12px;
        }
    }
}
