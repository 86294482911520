.container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #000000;
}

.title {
    font-size: 40px;
    line-height: 46px;
    font-weight: 400;
    color: #000000;
}
