.container {
    margin-left: auto;
    display: flex;
    gap: 10px;
}

.blueButton {
    cursor: pointer;
    padding: 15px 20px;
    border: 1px solid #1c4bcf;
    border-radius: 3px;
    background-color: #1c4bcf;
    color: #ffffff;
}

.whiteButton {
    cursor: pointer;
    padding: 15px 20px;
    border: 1px solid #1c4bcf;
    border-radius: 3px;
    background-color: #ffffff;
    color: #1c4bcf;
}
