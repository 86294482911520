@import 'simplebar/dist/simplebar.min.css';

.elisa-scroll {
    &.elisa-scroll-offset-2 {
        .simplebar-track.simplebar-vertical {
            right: 2px;
        }
    }

    .simplebar-track.simplebar-vertical {
        width: 3px;
        background: transparent;
        border-radius: 3px;
        pointer-events: all;
        right: 10px;

        @include media {
            width: 4px;
        }

        .simplebar-scrollbar::before {
            top: 0;
            bottom: 0;
            transition: all 0.15s ease-in-out;
        }
    }

    &-dropdown .simplebar-track.simplebar-vertical {
        background: transparent;
        border-radius: 0px;
    }

    .simplebar-scrollbar.simplebar-visible {
        border-radius: 3px;
        cursor: pointer;
        opacity: 0.6;

        &.simplebar-hover::before {
            opacity: 1;
        }
    }

    .simplebar-scrollbar:before {
        right: 0;
        left: 0;
        background: var(--color-5);
    }
}
