@import '/src/theme';

.root {
    padding: 20px 50px 20px 20px;
    background-color: $white;
    border-radius: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content {
    display: flex;
    gap: 84px;
    align-items: center;
    flex-grow: 1;
}

.image {
    border-radius: 32px;
    overflow: hidden;
    width: 100%;
    max-width: 139px;
    position: relative;
    min-height: 78px;

    img {
        object-fit: cover;
    }
}

.title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    transition: color 0.1s ease;
    cursor: pointer;

    &:hover {
        color: $accent;
    }
}

.price {
    white-space: nowrap;
    margin-left: 40px;
}

.price_title {
    color: $secondary;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 15px;
}

.price_value {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #2f3441;
}
