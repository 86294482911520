.root {
    width: 300px;

    * {
        font-family: 'Inter', sans-serif !important;
    }

    [data-elisa-scroll] [class^='simplebar-track simplebar-vertical'] {
        right: 6px;
        width: 4px;
    }
}

.wrapper {
    position: relative;
}

.close {
    position: absolute;
    top: 4px;
    right: 4px;
    color: var(--a-color-dark-gray);
    cursor: pointer;
    transition: 0.2s all ease;
    z-index: 1;
}

.back {
    position: absolute;
    top: 4px;
    left: 4px;
}

.header {
}

.title {
    padding: 12px 40px 4px;
    font: var(--head-5-7);
    text-align: center;
    color: var(--a-color-dark-gray);
}

.label {
    font: var(--head-5);
    color: var(--a-color-dark-gray);
    letter-spacing: 0.5px;
    padding: 0 10px;
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-height: 384px;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 1;
}
