@import '/src/theme';

.count {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.004em;
    text-transform: uppercase;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.label {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.004em;
    text-transform: uppercase;
    color: $primary;
}

.disabled {
    color: $tertiary;
}

.scroll {
    background-color: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow {
    width: 44px;
    height: 44px;
    padding: 0;
}
