.root {
    margin-bottom: 14px;

    button {
        text-transform: initial;
    }
}

.filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
