.head {
    background: rgba(25, 118, 210, 0.12);
}

.headWrapper {
    padding-bottom: 0;
}

.title {
    font: var(--head-2);
    margin-bottom: 32px;
}
