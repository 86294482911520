@import '/src/theme';

.root {
    margin-right: auto;
}

.tabs_list {
    border-bottom: 0;
    column-gap: 24px;
    row-gap: 8px;
    position: relative;
}

.tab {
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    color: $primary;
    padding: 0 0 25px;
    margin: 0;

    &:hover {
        border-color: transparent;
    }
}
