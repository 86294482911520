.root {
    display: block;
    position: relative;
}

.element {
    display: inline-flex;
    width: 100%;
}

.list {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 100%;
    max-width: 240px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    background: var(--color-1);
    padding: 14px 0;
    box-shadow: var(--shadow-popover);
    border-radius: 3px;
    margin-top: 6px;
    width: 100%;
    max-height: 178px;
    overflow: auto;
}

/**
 * Animation
 */

$duration: 100ms;

.css-enter {
    opacity: 0;
}

.css-enter-active {
    opacity: 1;
    transition: opacity $duration, transform $duration;
}

.css-exit {
    opacity: 1;
}

.css-exit-active {
    opacity: 0;
    transition: opacity $duration, transform $duration;
}
