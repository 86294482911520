.root {
}

.wrapper {
    display: flex;
    flex-direction: column;
    // overflow-x: auto;
    // overflow-y: hidden;
}

.grid {
    max-width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, minmax(300px, 1fr));
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 40px;
}

.row {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    // padding: 16px 0 10px;
    padding: 4px 0;
    min-height: 50px;
    column-gap: 12px;
    row-gap: 12px;
    border-bottom: 1px solid #dce0eb;

    &:last-child {
        border-bottom: 0;
    }
}

.label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2f3441;
    flex-grow: 1;
}

.value {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2f3441;
    text-align: right;
}
