.root {
    min-width: 160px;
    width: 100%;
}

.paper {
    max-height: 240px;
    max-width: 400px;

    [data-elisa-scroll] [class^='simplebar-track simplebar-vertical'] {
        right: 4px;
        width: 5px;
    }
}

.overflow {
    overflow: hidden;
    max-height: 240px;
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-height: 240px;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 1;
}

.item {
    white-space: break-spaces;
}

.notfound {
    font: var(--head-5);
    color: var(--a-color-dark-gray);
    text-align: center;
    padding: 8px;
    border-bottom: 1px solid #efefef;
}
