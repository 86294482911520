.root {
    display: flex;
    justify-content: space-between;
}

.row {
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-right: 16px;
    }
}

.item {
    &:not(:last-child) {
        margin-right: 16px;
    }
}
