@import '/src/theme';

.root {
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
    background-color: $background;
    border-radius: 15px;
}

.selected {
    &.root {
        background: $blue-light;
    }

    .description {
        color: $blue;
    }
}

.title {
    padding: 8px 0;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    label {
        font-size: 18px;
        font-weight: 600;
        line-height: 20px;
        position: relative;
        top: 1px;
    }

    .checkbox,
    :global(.mantine-Checkbox-body),
    :global(.mantine-Checkbox-labelWrapper) {
        width: 100%;
    }

    input:checked + svg {
        color: $dark;
    }
}

.checkbox {
    pointer-events: none;
}

.description {
    white-space: pre-line;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.price {
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
