@import '/src/theme';

.root {
    box-shadow: var(--shadow-popover);
    border-radius: 3px;
    padding: 40px 48px;
    max-width: 736px;
    min-height: 128px;
    width: calc(100% - 48px);
    background: var(--color-1);

    @include media {
        vertical-align: bottom;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        max-width: 100%;
        padding: 36px 20px 40px;

        form {
            max-width: 100%;
        }
    }
}

.body {
    overflow: hidden;
}

.close {
    top: 8px;
    right: 8px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-5);
    transition: all 0.1s ease;

    &:hover {
        color: var(--color-7);
    }
}

.icon {
    font-size: 32px;
}
