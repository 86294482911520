@import '/src/theme';

.root {
}

.inner {
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: flex-start;
    column-gap: 76px;
    row-gap: 76px;
    position: relative;
}

.image {
    width: 100%;
    max-width: 516px;
    // flex-shrink: 0;
    position: relative;

    img {
        object-fit: contain;
        border-radius: 16px;
    }
}

.info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.info_bottom {
    margin-top: 24px;

    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.info_bottom_item {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #6b7683;
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.title {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 8px;
    color: #2f3441;
}

.modification {
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 42px;
    color: #2f3441;
}

.specification {
    margin-bottom: auto;
}

.price {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}

.date {
}

.color {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
