.root {
    display: block;
    position: relative;
}

.element {
    display: inline-flex;
}

.list {
    display: flex;
    position: absolute;
    z-index: 1;
    max-width: 240px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    background: var(--color-1);
    padding: 10px 10px 8px;
    box-shadow: var(--shadow-popover);
    border-radius: 3px;
    margin-top: 6px;
}

.input {
    font: var(--head-6);
    padding: 10px;
}

.content {
    margin: 8px -10px 0;
    max-height: 150px;
    overflow: auto;
}

/**
 * Animation
 */

$duration: 100ms;

.css-enter {
    opacity: 0;
}

.css-enter-active {
    opacity: 1;
    transition: opacity $duration, transform $duration;
}

.css-exit {
    opacity: 1;
}

.css-exit-active {
    opacity: 0;
    transition: opacity $duration, transform $duration;
}
