@import '/src/theme';

.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    padding-top: 20px;
}

.option {
    border-bottom: 1px solid #e9e8e8;
    padding: 20px 0;
}

.grid {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
}

.inner {
    display: block;
    margin-top: 12px;

    > div {
        column-gap: 75px !important;
        // row-gap: 24px !important;
    }
}

.tab {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;

    &[data-active='true'] {
        font-weight: 600;
        color: $dark;
    }
}
