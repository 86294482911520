.root {
    max-width: 440px;
}

.title {
    text-align: center;
    margin-bottom: 12px;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    margin-bottom: 20px;
}
