@import '/src/theme';

.app-loader {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;

    opacity: 0;
    visibility: hidden;

    &-transparent {
        align-items: flex-start;
        background-color: rgba($white, 0.8);
        transition: all 0.1s 0.2s;
    }

    &-active {
        display: flex;
        opacity: 1;
        visibility: visible;
    }

    &-transparent &-container {
        margin-top: 30vh;
    }

    &-body {
        overflow: hidden;
    }

    &-container {
        // position: sticky;
        z-index: 1;
        // top: 80px;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // width: $container-size;
        margin-top: -30vh;
    }
}

.app-loader-inner {
    // display: inline-block;
    // position: relative;
    width: 80px;
    height: 80px;

    div {
        transform-origin: 40px 40px;
        animation: app-loader 1.2s linear infinite;
    }
    div:after {
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        left: 37px;
        width: 5px;
        height: 19px;
        border-radius: 20%;
        background: rgba($accent, 0.6);
    }
    div:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
    }
    div:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
    }
    div:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
    }
    div:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
    }
    div:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
    }
    div:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
    }
    div:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
    }
    div:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
    }
    div:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
    }
    div:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
    }
    div:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
    }
    div:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
    }
}

@keyframes app-loader {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
