.root {
    margin-bottom: 8px;
    display: block;
    position: relative;
    font: var(--head-6);
}

.required {
    &::after {
        content: '*';
        display: inline-block;
        margin-left: 2px;
        color: var(--color-7);
        font: inherit;
    }
}
