/* -------------------------------------------------------------------------- */
/*                                 Deprecated                                 */
/* -------------------------------------------------------------------------- */

/* Colors */
:root {
    --color-1: #ffffff;
    --color-2: #f3f7fa;
    --color-3: #eaedf5;
    --color-4: #dce0eb;
    --color-5: #acb6c3;
    --color-6: #6b7683;
    --color-7: #2f3441;
    --color-8: #00aafc;
    --color-9: #082894;
    --color-10: #1c4bcf;
    --color-11: #e62631;
    --color-13: #ea6b51;
    --color-14: #cceefe;
    --color-16: #6b7683;
    --color-17: #4cc86a;
    --color-20: #1e7ac2;

    --color-background-blue: #008dc9;

    --color-error-text: rgba(160, 174, 192);
    --color-error-background: rgba(247, 250, 252);
}

/* Typography */
:root {
    --font-main: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-second: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;

    --head-1: 400 42px/48px var(--font-main);
    --head-2: 400 32px/40px var(--font-main);
    --head-3: 400 22px/28px var(--font-main);
    --head-4: 400 18px/24px var(--font-main);
    --head-5: 400 16px/24px var(--font-main);
    --head-6: 400 14px/18px var(--font-main);
    --head-7: 400 12px/16px var(--font-main);
    --head-8: 400 16px/20px var(--font-main);

    --head-1-7: 700 42px/48px var(--font-main);
    --head-2-7: 700 32px/40px var(--font-main);
    --head-3-7: 700 22px/28px var(--font-main);
    --head-4-7: 700 18px/24px var(--font-main);
    --head-5-7: 700 16px/24px var(--font-main);
    --head-6-7: 700 14px/18px var(--font-main);
    --head-7-7: 700 12px/16px var(--font-main);
    --head-8-7: 700 16px/20px var(--font-main);
}

/* Shadows */
:root {
    --shadow-panel: 0px 1.2px 3.6px rgba(0, 0, 0, 0.03), 0px 6.4px 14.4px rgba(0, 0, 0, 0.07);
    --shadow-popover: 0px 2px 4px rgba(36, 47, 83, 0.06), 0px 1px 6px rgba(0, 0, 0, 0.1),
        0px 5px 24px rgba(36, 47, 83, 0.16);
}

/* Analytics */
:root {
    --a-color-blue: #1976d2;
    --a-color-indigo: #536dfe;
    --a-color-custom-blue: rgba(25, 118, 210, 0.12);
    --a-color-black: rgba(0, 0, 0, 0.87);
    --a-color-dark-gray: rgba(0, 0, 0, 0.54);
    --a-color-light-gray: rgba(0, 0, 0, 0.12);
    --a-color-white: #ffffff;
    --a-color-hover: #edf4fb;
    --a-color-back: #fafafa;
    --a-color-disabled: rgba(0, 0, 0, 0.26);
}
