.root {
    display: block;
    // overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    // width: calc(100% + 40px);
    // padding: 0 20px;
    // margin: 0 -20px;
    overflow: auto;
    max-height: 656px;
    position: relative;
    z-index: 0;
}

.table {
    border-spacing: 0;
    border: 0;
    width: 100%;
}

.thead {
    background: var(--color-2);
    position: sticky;
    top: 0;
    z-index: 1;
}

.th {
    font: var(--head-5);
    padding: 12px 10px;
    text-align: left;
    cursor: pointer;

    &Div {
        display: flex;
        align-items: flex-start;
    }

    &Icon {
        transition: all 0.1s ease;
        margin-left: 10px;
    }

    &[data-active] &Icon {
        transform: rotate(-180deg);
    }

    &Info {
        color: var(--color-6);
        margin-left: 10px;
    }
}

.tbody {
    .tr {
        &:hover {
            background: var(--color-2);

            &:first-child {
                .td {
                    border-top: 1px solid var(--color-3);
                }
            }
        }
    }

    .td {
        // border-bottom: 1px solid #E0E0E0;
        border-bottom: 1px solid var(--color-3);
        border-top: 1px solid transparent;
        word-break: keep-all;
    }
}

.tr {
    text-align: left;
}

.td {
    padding: 12px 10px;
    text-align: left;
    font: var(--head-5);
}

.actions {
    &List {
        width: 176px;
        right: 0;
    }

    &Icon {
        margin-right: 12px;
    }
}
