@import '@/pages/@deprecated/references/styles/mixins';

.root {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.title {
    font-size: 23px;
    line-height: 32px;
    font-weight: 400;
    color: #000000;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.fields {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: flex-end;
    width: 100%;
}

.field {
    @include layout-width(4, 24);
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.fullWidth {
    width: 100%;
}

.button {
    @include button-reset;
    display: flex;
    align-items: center;
    padding: 9px 9px 9px 0;
    gap: 9px;

    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #1976d2;

    cursor: pointer;

    svg {
        transition: all 150ms ease-in-out;
    }
}

.show {
    composes: button;

    svg {
        transform: rotate(-180deg);
    }
}

.error {
    > div {
        margin-bottom: 20px;
    }
}
