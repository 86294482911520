.root {
    width: 100%;
    margin-top: 10px;
}

.rows {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    // margin: 0 -10px;
    padding: 8px 10px;
    color: var(--a-color-dark-gray);
    font: var(--head-5);

    &:hover {
        background: var(--a-color-hover);
    }
}

.rowActive {
    background: var(--a-color-back);
}

.icon {
    font-size: 24px;
}

.notfound {
    font: var(--head-5);
    color: var(--a-color-dark-gray);
    text-align: center;
    padding: 8px;
    padding-bottom: 16px;
}
