.root {
    max-width: 520px;

    [data-elisa-scroll] [class^='simplebar-track simplebar-vertical'] {
        right: 2px;
        width: 6px;
    }

    [data-elisa-scroll] [class^='simplebar-track simplebar-horizontal'] [class^='simplebar-scrollbar'] {
        top: 2px;
        height: 7px;
        cursor: pointer;
        pointer-events: all;

        &::before {
            transition: 0.1s all ease;
        }
    }
}

.title {
    margin-bottom: 20px;
}

.fields {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-bottom: 40px;
    max-height: 500px;
    overflow: auto;
    padding-right: 12px;
    padding-top: 12px;
}

.input {
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.actions {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;

    button:first-child {
        margin-right: 20px;
    }
}
