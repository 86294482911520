.root {
    &:hover {
        background: var(--a-color-back);

        td {
            background: var(--a-color-back) !important;
        }
    }
}

.selected {
    background: #eef6fd;

    td {
        background: #eef6fd !important;
    }

    &:hover {
        background: #eef6fd;

        td {
            background: #eef6fd !important;
        }
    }
}
