.root {
    [data-row] {
        &:hover {
            background: none;
        }
    }

    th {
        white-space: nowrap;

        &:hover {
            &:not([data-action]) {
                background: var(--a-color-back) !important;

                span {
                    color: inherit;
                }
            }
        }
    }
}
